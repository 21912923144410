body {
  font-family: "Montserrat", sans-serif;
  font-size: 14px;
  font-weight: bold;
}
.logo {
  /* width: 250px !important;
  height: 80px !important; */
 padding-right: 36px;
 padding-left: 0px;
 width: 45%;
}
.customnav {
  /* background: #d7f2fb; */
  background-color: #1fc4c4;
  border-radius: 20px;
  margin: 10px;
  padding: 2px;
  display: flex;
  align-items: center;
}
.customnav:hover {
  /* background: #005198; */
  background: #e34e30;
  border-radius: 20px;
  transition: 0.5s ease-in-out;
  border-color: none;
  color: #fff !important;
}
.customnav:hover .nav-link {
  color: #fff !important;
}
.nav-link{
  /* color: #000000; */
  color: #FFFFFF;
  text-align: center;
  width: 100%;
}
.nav-link.active {
  color: #fff !important;
  /* background: #005198; */
  background: #e34e30;
  border-radius: 20px;
}

.navbar{
  /*   z-index should be above .close-navbar-toggler */
    z-index:2;
    /* background-color: #0e1110; */
    margin: 0;
    padding: 0;
    margin-top: 3.4rem;
  }
  .close-navbar-toggler{
    position:absolute;
    top:0;
    left:0;
    height:100%;
    width:100%;
    z-index:1;
    cursor:pointer;
  }
  .close-navbar-toggler.collapsed{
    z-index:-1;
  }

@media only screen and (max-width: 756px) {
  .navbar {
    background: #fff !important;
    text-align: center;
  }
  .logo {
    width: 200px !important;
    height: 80px !important;
    line-height: 80px;
  }
}
@media screen and (max-width:767px) {
  .navbar{
    margin: 0;
    padding: 0;
  }
}
