body {
  font-family: "Montserrat", sans-serif;
}
.link {
  text-decoration: none;
}

.main {
  margin: 100px;
}
.main-text {
  font-family: "Merriweather";
  font-size: 44px;
  font-weight: bold;
  text-align: left;
}
.p-text {
  font-family: "Montserrat";
  font-size: 20px;
  font-weight: normal;
  margin-top: 50px;
  text-align: left;
}
.contact-icons{
  margin: 0 1rem;
  font-size: 18px;
  cursor: pointer;
}
.contact-icons :hover{
  color:gray;
}
.btn {
  font-family: "Montserrat";
  font-size: 16px;
  font-weight: 100;
  /* background: #005198 !important; */
  background: #e34e30 !important;
  color: #fff !important;
  /* float: left; */
  padding: 10px 20px;
  width: auto;
}
.btn-brochure {
  font-family: "Montserrat";
  font-size: 16px;
  font-weight: 100;
  background: #005198 !important;
  /* background: #e34e30 !important; */
  color: #fff !important;
  /* float: left; */
  padding: 10px 20px;
  width: auto;
  border: none;
  border-radius: 8px;
}

.btn:hover {
  font-family: "Montserrat";
  font-size: 16px;
  font-weight: 100;
  background: #fff !important;
  /* color: #005198 !important; */
  color: #e34e30 !important;
  /* border: 1px solid #005198 !important; */
  border: 1px solid #e34e30 !important;
  /* float: left; */
  padding: 10px 20px;
  width: auto;
}
.btn-brochure:hover {
  font-family: "Montserrat";
  font-size: 16px;
  font-weight: 100;
  background: #fff !important;
  color: #005198 !important;
  /* color: #e34e30 !important; */
  border: 1px solid #005198 !important;
  /* border: 1px solid #e34e30 !important; */
  /* float: left; */
  padding: 10px 20px;
  width: auto;
}

.section-2 {
  margin: 5px 0;
  padding: 5px;
}
.dot {
  height: 30px;
  width: 30px;
  border-radius: 50%;
  background: #1fc4c4;
  /* background: #4cfc9b; */
  position: relative;
  margin-top: 10px;
  padding-right: 20px;
}
.search {
  align-items: center;
  position: absolute;
  top: 22%;
  left: 24%;
  font-size: 18px;
  color: #FFFFFF;
  /* font-size: 18px;
  color: #FFFFFF; */
}

.para {
  line-height: 35px;
  padding-left: 10px;
  padding-top: 5px;
  width: 100%;
  margin:0px 10px;
  border: solid 1px gray;
  border-radius:  10px ;
}
.about {
  color: White;
  background: #1fc4c4;
  /* background: linear-gradient(to top, rgb(146, 222, 236), rgb(175, 227, 244)); */
  height: 45px;
  line-height: 45px;
  text-align: left;
}
.downarrow {
  float: right;
}

.section-3 {
  margin-top: 60px;
  /* background: #8fe4ff !important; */
  /* background: var(--blue-brand, linear-gradient(180deg, #00BEF2 0%, #014F93 100%)); */
  align-items: center;
  position: relative;
  padding: 0 !important;
}

.row {
  --bs-gutter-x: 0 !important;
}
.box {
  height: 60px;
  width: 600px;
  background: #fff;
  text-align: center;
  margin-left: 100px;
  margin-top: -60px;
  position: absolute;
  z-index: 2;
}
.box h2 {
  line-height: 60px;
  display: flex;
  align-items: center;
}
.bar {
  margin: 0 30px;
  width: 133px;
  height: 10px;
  background: #005198;
  color: #005198;
}
.bar1 {
  margin: 0 0px 10px;
  width: 133px;
  height: 10px;
  background: #005198;
  color: #005198;
}
.bar2 {
  margin: 0px 0px 10px;
  width: 133px;
  height: 3px;
  background: #005198;
  color: #005198;
}

.bar5 {
  margin: 25px 20px 10px 0px;
  width: 133px;
  height: 3px;
  background: #005198;
  color: #005198;
  display: flex;
}
.para1 {
  padding: 10px;
  text-align: left;
  /* color: #fff; */
  color: #301680;
  font-size: 16px;
}

.section-4 {
  margin: 100px 0;
  /* background: var(--blue-brand, linear-gradient(180deg, #00BEF2 0%, #014F93 100%)); */
  background: var(--blue-brand, linear-gradient(180deg, #1fc4c4 0%, #201b5c 100%));
  height: auto;
  padding: 30px;
  align-items: center;
  color: #fff;
}
.count {
  font-family: "Merriweather";
  font-size: 84px;
  font-weight: bold;
  text-align: center;
}
.countp {
  font-family: "Merriweather";
  font-size: 24px;
  font-weight: bold;
  text-align: center;
}
.countptext {
  font-family: "Merriweather";
  font-size: 24px;
  font-weight: bold;
  text-align: center;
  color: #005198;
  padding: 7px;
  align-items: stretch;
}
.countpt {
  font-family: "Merriweather";
  font-size: 24px;
  font-weight: bold;
  text-align: left;
}
.box3 {
  height: 210px;
  width: 310px;
}

.box3 > h1 {
  font-family: "Merriweather";
  font-size: 84px;
  font-weight: bold;
  text-align: center;
}
.box3 > p {
  font-family: "Merriweather";
  font-size: 24px;
  font-weight: bold;
  text-align: center;
}
.box4 {
  height: 260px;
  width: 450px;
}
.box4 > div {
  border: 1px dotted #fff;
  padding: 10px;
}
.box4 > div > div {
  border: 5px solid #fff;
  padding: 10px;
}

.box4 > div > div > p {
  font-family: "Merriweather";
  /* font-size: 30px; */
  font-size: 21px;
  font-weight: bold;
  text-align: center;
}

.section-5 > h3 {
  text-align: center;
}

.box-img {
  position: relative;
  /* width: 18%; */
  margin: 2px;
}
.image {
  max-width: 100%;
  height: auto;
}

.box-img::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 35px;
  height: 35px;
  /* background: #8fe4ff; */
  background: #1fc4c4;
  opacity: 1;
  transition: 500ms ease-in;
  border-radius: 0 0 60% 0;
}

.box-img:hover::before {
  width: 100%;
  height: 100%;
  opacity: 0.5;
  border-radius: 0;
}
/* image caurosel */
.carousel .control-dots .dot.selected {
  width: 100px;
  height: 5px;
  border-radius: 0%;
  background: #005198;
}


.footer {
  margin-top: -10px;
  background: black;
  height: 60px;
  text-align: center;
  line-height: 60px;
}
.footertext {
  color: #fff;
  font-family: "montserrat";
  font-size: small;
  font-weight: 16px;
}
.mainperson {
  padding: 100px;
}
.person {
  border: 5px solid #fff;
  margin: 5px 20px 5px 0px;
  background: #005198;
  position: relative;
  display: inline-block;
  overflow: hidden;
  color: #fff;
}

.image {
  z-index: 1;
}
.img {
  display: block;
  width: 100%;
  height: auto;
}

.overlay {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: #fff;
  overflow: hidden;
  width: 0;
  height: 100%;
  transition: 0.5s ease;
  color: black;
}

.person:hover .overlay {
  width: 100%;
}

.text {
  color: black;
  font-size: 12px;
  display: block;
  height: auto;
  position: absolute;
  top: 0%;
  left: 35%;
  /* -webkit-transform: translate(-50%, -50%); */
  /* -ms-transform: translate(-50%, -50%); */
  /* transform: translate(-50%, -50%); */
  white-space: wrap;
}
.fooo {
  margin: 13px 30px;
  width: 133px;
  height: 5px;
  background: #fff;
  color: #fff;
}

.arrowlink {
  background: #005198;
  color: #fff;
  align-items: center;
  padding: 90px 2px;
}

.banner {
  /* background: linear-gradient(180deg, #00BEF2 0%, #014F93 100%); */
  background: var(--blue-brand, linear-gradient(180deg, #1fc4c4 0%, #201b5c 100%));
  margin-top: 9.4rem;
}

.ctsus {
  font-family: "morrieweather";
  font-size: 36px;
  font-weight: bold;
}

.ctsus2 {
  font-family: "merriweather";
  font-size: 28px;
  font-weight: bold;
}

.ctusp {
  font-family: "Montserrat";
  font-size: 20px;
  font-weight: normal;
}
.loctext {
  font-family: "inter";
  font-size: 28px;
  font-weight: bold;
}
.locptext {
  font-family: "Montserrat";
  font-size: 20px;
  font-weight: normal;
}

.clienth1 {
  font-family: "merriweather";
  font-size: 40px;
  font-weight: bold;
  color: #fff;
  text-align: center;
  margin-top: 20px;
  padding: 20px;
}
.serviceh1 {
  font-family: "merriweather";
  font-size: 40px;
  font-weight: bold;
}

/* recurment */

.recbanner {
  font-family: "merriweather";
  font-size: 38px;
  font-weight: bold;
  text-align: left;
  transform: translateY(30%);
  color: #fff;
}
.recbanner5 {
  font-family: "merriweather";
  font-size: 44px;
  font-weight: bold;
  text-align: left;
  transform: translateY(50%);
  margin-top: 25px;
  padding-left: 130px;
  color: #fff;
}
.cutcolor {
  color: #005198;
}
.list1 > li {
  padding: 7px 10px;
  box-shadow: #8fe4ff;
}

/* diagnostics
.recbanner1 {
  font-family: "merriweather";
  font-size: 44px;
  font-weight: bold;
  text-align: left;
  transform: translateY(50%);
  color: #fff;
  margin-top: 50px;
  padding: 0px 100px;
} */

/* ourstory */
.card-img-top{
  width: 100%;
}
.zoom-on-hover {
  transition: transform 0.3s ease;
  text-align: center;
}

.zoom-on-hover:hover {
  transform: scale(1.1);
  cursor: pointer;
}

.recbanner1 {
  font-family: "merriweather";
  font-size: 44px;
  font-weight: bold;
  text-align: left;
  transform: translateY(50%);
  color: #fff;
  margin-top: 40px;
  margin: auto;
  padding: 0px 0px 0px 150px;
}

/* Styling for the file input */
.file-input {
  position: relative;
  display: inline-block;
  overflow: hidden;
  margin-bottom: 10px;
}

.file {
  position: absolute;
  left: 0;
  top: 0;
  opacity: 0;
  width: 100%;
  height: 100%;
  cursor: pointer;
}

/* Styling for the file label (upload button) */
.file-label {
  display: inline-block;
  padding: 8px 20px;
  background-color: #007bff;
  color: #fff;
  border-radius: 5px;
  cursor: pointer;
}

.file-label:hover {
  background-color: #0056b3;
}

.managepara {
  color: #005198;
}

.whatsapp {
  position: fixed;
  padding: 10px;
  right: 0;
  bottom: 0;
}
.resmap {
  width: 600px;
  height: 420px;
}
.socailp {
  text-align: center;
  margin-left: 30px;
}
.lnameinput {
  width: 310px;
}
.lnameinput2 {
  width: 295px;
}
.fnameinput {
  width: 290px;
}

.clienttext {
  color: var(--color-1-text-main, #333);
  font-family: Merriweather;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 204%; /* 48.96px */
  letter-spacing: 1.2px;
}
.desname {
  color: var(--brand-dark, #005198);
  font-family: Montserrat;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 140%; /* 28px */
  letter-spacing: 1px;
}
.destext {
  color: var(--brand-dark, #005198);
  font-family: Montserrat;
  font-size: 20px;
  font-style: italic;
  font-weight: 400;
  line-height: 140%;
  letter-spacing: 1px;
}
.clientimage {
  width: 150px;
  height: 150px;
  background: "lightgray 50% / cover no-repeat";
  border-radius: 50%;
  margin: 20px 0 20px 20px;
  float: "right" !important;
  align-items: "center";
}

.btn-brochures{
  padding: 16px;
  border: none;
  border-radius: solid  16px brown;
  background-color: brown;
  
}
.CEO-text{
  line-height: 35px;
  font-size: 16px;
  text-align: center;
}

/*Certified images*/

.certified-images{
  padding: 56px 30px 10px 0px;
  width: 80%;
  height: auto;
}

.certified-text{
  padding-right: 36px;
  text-align: center;
}
.s-icons{
  color: brown;
  font-size: 5em;
  cursor: pointer;
}
.s-icons :hover{
  color: black;
  cursor: pointer;
}
.s-container {
  display: flex;
  flex-direction: column;
  align-items: center; 
  list-style: none;
}

.s-icon-container {
  display: flex;
  align-items: center; 
  margin-right: 10px; 
}
.s-icon-container {
  margin-bottom: 10px; 
}

.s-text{
  color: #030302;
  text-decoration: none;
  font-size: 21px;
}
.s-text :hover{
  color: brown;
  font-weight: 800;
  
}
.contact-top-section{
  height: 8%;
}
.doctor-banner-container{
  margin-top: 9.4rem;
}
.mobile-version-subscribe{
  width: 500px;
  height: 45px;
  padding:10px;
  font-Size: 16px;
}
.map-section{
  position: relative;
}
.map-icon{
  font-size: 30px;
  top:40%;
  left:65.3%;
  color: red;
  position: absolute;
  cursor: pointer;
  transition: opacity 0.3s ease;
}
.map-icon1{
  font-size: 30px;
  top:40%;
  left:40%;
  color: red;
  position: absolute;
  cursor: pointer;
}
.map-icon2{
  font-size: 30px;
  top:60%;
  left:20%;
  color: red;
  position: absolute;
  cursor: pointer;
}
.map-icon3{
  font-size: 30px;
  top:71%;
  left:80%;
  color: red;
  position: absolute;
  cursor: pointer;
}
.map-icon4{
  font-size: 30px;
  top:10%;
  left:60%;
  color: red;
  position: absolute;
  cursor: pointer;
}
.map-icon5{
  font-size: 30px;
  top:10%;
  left:10%;
  color: red;
  position: absolute;
  cursor: pointer;
}
.map-icon6{
  font-size: 30px;
  top:40%;
  left:55.3%;
  color: red;
  position: absolute;
  cursor: pointer;
  transition: opacity 0.3s ease;
}
.map-icon7{
  font-size: 30px;
  top:29%;
  left:58.3%;
  color: red;
  position: absolute;
  cursor: pointer;
  transition: opacity 0.3s ease;
}
.vacancy-info{
font-size: 18px;
background-color: black;
padding: 8px 16px;
border-radius: 8px;
color: #FFFFFF; 
text-align: center;
width: 50%;
}
.map-icon8{
  font-size: 30px;
  top:40%;
  left:58.3%;
  color: red;
  position: absolute;
  cursor: pointer;
  transition: opacity 0.3s ease;
}
.map-icon9{
  font-size: 30px;
  top:35%;
  left:55.3%;
  color: red;
  position: absolute;
  cursor: pointer;
  transition: opacity 0.3s ease;
}
.map-icon10{
  font-size: 30px;
  top:24%;
  left:52.3%;
  color: red;
  position: absolute;
  cursor: pointer;
  transition: opacity 0.3s ease;
}
.map-icon11{
  font-size: 30px;
  top:38%;
  left:50.3%;
  color: red;
  position: absolute;
  cursor: pointer;
  transition: opacity 0.3s ease;
}
.map-icon12{
  font-size: 30px;
  top:28%;
  left:54.3%;
  color: red;
  position: absolute;
  cursor: pointer;
  transition: opacity 0.3s ease;
}
.map-icon13{
  font-size: 30px;
  top:30%;
  left:62.3%;
  color: red;
  position: absolute;
  cursor: pointer;
  transition: opacity 0.3s ease;
}
.map-icon .vacancy-info, .map-icon1 .vacancy-info, .map-icon2 .vacancy-info,.map-icon3 .vacancy-info,.map-icon4 .vacancy-info,.map-icon5 .vacancy-info,.map-icon6 .vacancy-info,.map-icon7 .vacancy-info,.map-icon8 .vacancy-info,.map-icon9 .vacancy-info,.map-icon10 .vacancy-info,.map-icon11 .vacancy-info,.map-icon12 .vacancy-info,.map-icon13 .vacancy-info {
  display: none; 
}


.map-icon:hover .vacancy-info,.map-icon1:hover .vacancy-info,.map-icon2:hover .vacancy-info,.map-icon3:hover .vacancy-info,.map-icon4:hover .vacancy-info,.map-icon5:hover .vacancy-info,.map-icon6:hover .vacancy-info,.map-icon7:hover .vacancy-info,.map-icon8:hover .vacancy-info,.map-icon9:hover .vacancy-info,.map-icon10:hover .vacancy-info,.map-icon11:hover .vacancy-info,.map-icon12:hover .vacancy-info,.map-icon13:hover .vacancy-info {
  display: block; 
}
.circle{
  width: 55px;
  height: 55px;
  border: 0.5px solid grey;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 800;
  font-size: 20px;
}

@media only screen and (max-width: 767px) {
  .main {
    margin: 20px !important;
  }
  .main-text {
    margin-top: 100px !important;
    font-family: "Merriweather";
    font-size: 30px !important;
    font-weight: bold;
    text-align: left;
  }
  .para{
    margin-bottom: 10px;
  }
  .p-text {
    font-family: "Montserrat";
    font-size: 14px !important;
    font-weight: normal;
    margin-top: 30px;
    text-align: left;
  }
  .box4 {
    margin: auto;
  }
  .box-img {
    padding: 10px;
    align-items: center;
    text-align: center;
  }
  .control-dots {
    margin-top: 50px !important;
  }

  .backimg {
    align-items: center !important;
    text-emphasis: none;
  }
  .cardborder {
    padding: 20px !important;
    border-bottom: 1px solid #005198;
  }
  .social {
    align-items: center !important;
    justify-content: space-evenly !important;
  }
  .terms {
    margin: 15px 0;
  }
  .socail.img {
    margin: 5px !important;
  }
  .socailp {
    justify-content: center !important;
  }

  .recbanner {
    font-size: 20px;
    margin-bottom: 40px;
  }
  .bottom {
    padding-bottom: 50px;
  }
  .resmap {
    width: 380px;
    height: 250px;
  }
  .serviceh1 {
    align-items: center;
    font-family: "merriweather";
    font-size: 25px;
    font-weight: bold;
  }
  .rightarrow {
    margin-left: -20px !important;
    margin-top: 10px;
  }
  .btnKnow {
    margin-top: 8px;
    margin-bottom: 8px;
  }

  .box {
    width: 350px;
    background: #fff;
    text-align: center;
    top: 20px !important;
    position: relative !important;
    margin: auto !important;
  }
  .bar {
    margin: 0 10px;
    width: 90px;
    height: 8px;
    background: #005198;
    color: #005198;
  }
  .para1 {
    margin-top: 40px;
    padding: 10px;
    text-align: left;
  }

  .section-4 {
    margin: 50px 0;
  }
  .section6 {
    margin: 20px 0px;
  }
  .blogsection {
    display: block !important;
    text-align: center;
    justify-content: center !important;
  }
  .blogheading {
    display: flex;
    text-align: center;
    justify-content: center !important  ;
  }
  .bar5 {
    display: none;
  }

  .mainperson {
    margin: 20px 0 !important;
    padding: 5px !important;
  }
  .person {
    border: 5px solid #fff;
    margin: auto;
    background: #005198;
    position: relative;
    display: inline-block;
    overflow: hidden;
    color: #fff;
  }
  .img {
    font-size: small;
    padding-top: 5px;
    font-weight: 300;
  }
  .person > div > p {
    font-size: small;
    font-weight: 100;
  }
  .image {
    width: 100% !important;
    height: auto !important;
  }
  .arrowlink {
    background: #005198;
    color: #fff;
    align-items: center;
    padding: 5px 2px;
    align-items: center;
    text-align: center;
    margin-left: -5px;
    border: 1px solid #007bff;
  }
  .overlay {
    position: absolute;
    bottom: 0;
    left: 35px !important;
    /* right: 0; */
    background-color: #fff;
    overflow: hidden;
    width: 0;
    height: 100%;
    transition: 0.5s ease;
    color: black;
  }
  .person:hover .overlay {
    width: 100%;
  }
  .person:hover .overlaybox {
    display: block !important;
  }

  .text > div > h5 {
    font-size: 16px;
  }
  .text > div > p {
    font-size: 11px;
    margin-right: 30px;
  }
  .lnameinput {
    width: 100%;
    margin-top: 15px;
  }
  .fnameinput {
    width: 100%;
  }
  .lnameinput2 {
    margin-top: 15px;
    width: 100%;
  }
  .whatsapp {
    position: fixed;
    padding: 0;
    right: 0;
    bottom: 0;
    width: 80px;
  }
  .socaillogo {
    width: 300px;
  }
  .footertext {
    color: #fff;
    font-family: "montserrat";
    font-size: 10px;
    font-weight: 6px;
  }
  .recbanner {
    font-family: "merriweather";
    font-size: 20px;
    font-weight: bold;
    text-align: left;
    transform: translateY(30%);
    color: #fff;
  }
  .banner {
    margin-top: 120px;
  }
  .recbanner5 {
    font-family: "merriweather";
    font-size: 26px;
    font-weight: bold;
    text-align: left;
    margin: 10px 0px 100px 0px !important;
    padding: 10px 10px;
    color: #fff;
  }
  .recbanner1 {
    font-family: "merriweather";
    font-size: 22px;
    font-weight: bold;
    text-align: left;
    transform: translateY(40%);
    color: #fff;
    margin: auto;
    padding: 0px 0 150px 5px;
  }
  .section-4 {
    padding-bottom: 50px;
  }
  .ctsus {
    font-family: "morrieweather";
    font-size: 26px;
    font-weight: bold;
    margin-top: 110px;
    text-align: center;
    padding-bottom: 20px;
  }
  .helthfrm {
    margin-top: 110px;
    text-align: center;
    padding-bottom: 20px;
  }
  .clienttext {
    color: var(--color-1-text-main, #333);
    font-family: Merriweather;
    font-size: 18px;
    font-style: normal;
    font-weight: 300;
    line-height: 104%; /* 48.96px */
    letter-spacing: 1.2px;
  }
  .desname {
    color: var(--brand-dark, #005198);
    font-family: Montserrat;
    font-size: 15px;
    font-style: normal;
    font-weight: 300;
    /* line-height: 70%; 28px */
    letter-spacing: 1px;
  }
  .destext {
    color: var(--brand-dark, #005198);
    font-family: Montserrat;
    font-size: 15px;
    font-style: italic;
    font-weight: 200;
    /* line-height: 70%; */
    letter-spacing: 1px;
  }
  .clientimage {
    width: 100px;
    height: 100px;
    background: "lightgray 50% / cover no-repeat";
    border-radius: 50%;
  }
  .certified-section{
    flex-direction: column;
  }
  .service-icons-section{
    flex-direction: column;
  }
  .brochures{
    flex-direction: column;
    text-align: center;
    margin: 1rem;
  }
  .view-brochures{
    margin-bottom: 1rem;
  }
  .btn-brochure{
    margin-top: 1rem;
  }
  .contact-top-section{
    display: none;
  }
  .mobile-version-subscribe{
    width: 130px;
  }
  .map{
    width: 100%;
  }
  .vacancy-info{
    font-size: 10px;
    width:auto;
    padding: 6px 10px;
  }
  .map-icon,.map-icon1,.map-icon2,.map-icon3,.map-icon4,.map-icon5,.map-icon6,.map-icon7,.map-icon8,.map-icon9,.map-icon10,.map-icon11,.map-icon12,.map-icon13{
    font-size: 18px;
    /* left: 69%; */
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px)  {
  .main {
    margin-left: 10px !important;
  }
  .main-text {
    margin-top: 30px !important;
    font-family: "Merriweather";
    font-size: 20px !important;
    font-weight: bold;
    text-align: left;
  }
  .p-text {
    font-family: "Montserrat";
    font-size: 7px !important;
    font-weight: normal;
    margin-top: 20px;
    text-align: left;
  }
  .btn {
    font-family: "Montserrat";
    font-size: 12px;
    font-weight: 100;
    background: #005198;
    color: #fff;
    /* float: left; */
    padding: 5px 10px;
    width: auto;
  }
  .btnKnow {
    margin-top: 0px;
    margin-bottom: 8px;
    width: 150px;
    height: 45px;
    margin-left: 5px;
  }
  .box4 {
    height: 260px;
    width: 450px;
    margin-left: 100px;
    margin-top: 10px;
  }
.section-5{
  text-align: center;
  justify-content: space-evenly;
}
.section6 {
  margin: 50px 0px;
}
}
