.lines-container {
  position: relative;
}

.icon, .icon1 {
  cursor: pointer;
  color: white;
}

.icon {
  margin-bottom: 2rem;
  color: rgba(0, 0, 0, 0.5);
}

.icon1 {
  position: absolute;
  top: 420%;
  left: 45%;
  font-size: 60px;
}

.icon-lines-text {
  display: flex;
  justify-content: center;
  text-align: center;
  align-items: center;
}

.horizontal-line {
  position: absolute;
  top: 259%;
  left: 0;
  width: 0;
  height: 1px;
  background-color: white;
  transition: width 0.5s ease;
}

.horizontal-line.animated {
  width: 1850%;
}

.vertical-line {
  position: absolute;
  width: 1px;
  height: 0;
  background-color: white;
}
.vertical-line.animated {
  animation: slideInVertical 0.5s ease forwards;
}

@keyframes slideInVertical {
  from { height: 0; }
  to { height: 100%; }
}

.line-text {
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  text-align: center;
  display: flex;
  justify-content: center;
}

.line-texts {
  padding: 8px 16px;
  background-color: white;
  border-radius: 8px;
  font-size: 16px;
}

.lines-image {
  width: 100%;
  position: relative;
  padding-bottom: 46px;
}

.lines-section {
  position: absolute;
  top: 400%;
}

.lines-cover {
  position: absolute;
  background-color: rgba(0, 0, 0, 0.5);
  width: 100%;
  height: 112vh;
  top: 379%;
}

@media screen and (max-width:767px) {
 .lines-image,.lines-cover,.how-mobile-version,.vertical-line,.icon,.icon1,.horizontal-line,.line-texts,.line-text{
  display: none;
  
 }
}
